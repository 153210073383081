var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "draggableContainer",
    staticClass: "position-fixed",
    class: {
      'show': _vm.y > 250,
      'd-none': _vm.y <= 250
    },
    style: "z-index: 9999; cursor: pointer; opacity: .6; bottom: ".concat(_vm.isMobileView ? '8%' : '5%', "; right: ").concat(_vm.isMobileView ? '10px' : '30px', ";"),
    on: {
      "mousedown": _vm.dragMouseDown,
      "touchstart": _vm.dragTouchDown
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-icon",
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.scrollToTop
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ArrowUpIcon",
      "size": _vm.isMobileView ? '16' : '28'
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }