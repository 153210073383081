var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toastification"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-avatar', {
    staticClass: "mr-75 flex-shrink-0",
    attrs: {
      "variant": _vm.variant,
      "size": "2rem"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.icon,
      "size": "15"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-grow-1"
  }, [_c('div', [_vm.title ? _c('h5', {
    staticClass: "mb-0 font-weight-bolder toastification-title",
    class: "text-".concat(_vm.variant),
    domProps: {
      "textContent": _vm._s(_vm.title)
    }
  }) : _vm._e(), _vm.text ? _c('small', {
    staticClass: "d-inline-block text-body",
    domProps: {
      "textContent": _vm._s(_vm.text)
    }
  }) : _vm._e()]), _c('span', {
    staticClass: "cursor-pointer toastification-close-icon ml-auto ",
    on: {
      "click": function click($event) {
        return _vm.$emit('close-toast');
      }
    }
  }, [!_vm.hideClose ? _c('feather-icon', {
    staticClass: "text-body",
    attrs: {
      "icon": "XIcon"
    }
  }) : _vm._e()], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }