// import { nextTick } from '@vue/composition-api'
/* NOTE:
  1. Mặc định trim() và xoá các khoảng trắng dup trong chuỗi khi [@blur].
  2. Modifier @input: [upperCase]
  3. Modifier @blur: [removeAccents, allowOnlyLetters, allowLettersAndNumber]
*/

/* USE:
    1. Dùng mặc định
    => v-input-formatter

    2. Thêm modifier
    => v-input-formatter.upperCase.allowOnlyLetters.removeAccents
*/

export default {
  bind(el, binding) {
    if (binding.value === false) return

    let inputOrTextarea

    if (el.tagName === 'INPUT' || el.tagName === 'TEXTAREA') {
      inputOrTextarea = el
    } else {
      inputOrTextarea = el.querySelector('input') || el.querySelector('textarea')
    }

    if (inputOrTextarea) {
      inputOrTextarea.addEventListener('input', function () {
        let newValue = this.value
        // const beforeValue = newValue
        // let cursorPos = this.selectionStart

        // const start = this.selectionStart
        // const end = this.selectionEnd

        if (binding.modifiers?.upperCase) { // Chuyển đổi chữ thường thành chữ in hoa
          newValue = newValue.toUpperCase()
        }

        // ANCHOR: removeAccents
        // if (binding.modifiers?.removeAccents) {
        // const originalLength = newValue.length
        // newValue = newValue
        // .normalize('NFD')
        // .replace(/\d/g, '')
        // .replace(/[\u0300-\u036f]/g, '')
        // .replace(/đ/g, 'd')
        // .replace(/Đ/g, 'D')

        // const differenceInLength = originalLength - newValue.length // Update cursor
        // cursorPos -= differenceInLength // Set position
        // }

        // if (newValue !== beforeValue) { // Update cursor
        //   const difference = beforeValue.length - newValue.length
        //   cursorPos -= difference
        // }

        this.value = newValue

        // nextTick(() => { // Update cursor
        // this.setSelectionRange(start, end)
        // })
      })

      inputOrTextarea.addEventListener('blur', function () {
        if (binding.modifiers?.removeAccents) {
          this.value = this.value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/đ/g, 'd')
            .replace(/Đ/g, 'D')
        }

        let regex = null
        if (binding.modifiers?.allowOnlyLetters) {
          regex = /[^a-zA-Z\s]/g
        }
        if (binding.modifiers?.allowLettersAndNumber) {
          regex = /[^a-zA-Z0-9\s]/g
        }
        if (regex) {
          this.value = this.value.replace(regex, '')
        }

        this.value = this.value.replace(/\s+/g, ' ').trim()
      })
    }
  },
}
