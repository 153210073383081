import JwtService from './jwtService'

export default function useJwt(
  axiosIns,
  jwtOverrideConfig,
  axiosInsUser = null,
) {
  const jwt = new JwtService(axiosIns, jwtOverrideConfig, axiosInsUser)

  return {
    jwt,
  }
}
